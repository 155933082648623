import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/brandstories/BrandStory1.css'; // Use the common CSS file for consistency
import '../styles/Brand.css'; // Additional CSS for the carousel
import MRM17 from '../assets/MRM17.png';
import KingKong300 from '../assets/kingkong300.jpg';
import Corona from '../assets/corona.png';
import Bufficorn from '../assets/Bufficorn.png';
import Commodorec64 from '../assets/Commodorec64.jpg';

const Brand = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const stories = [
    { year: '2015', img: Commodorec64, title: <b><center>Our Evergreen Card Game</center></b>, link: '/brand/brandstory1' },
    { year: '2017', img: MRM17, title: <b><center>Webweek Rhein-Main</center></b>, link: '/brand/brandstory2' },
    { year: '2019', img: KingKong300, title: <b><center>Rare NFTs</center></b>, link: '/brand/brandstory3' },
    { year: '2020', img: Corona, title: <b><center>Blockchain Tickets</center></b>, link: '/brand/brandstory4' },
    { year: '2022', img: Bufficorn, title: <b><center>Our Accolades</center></b>, link: '/brand/brandstory5' },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % stories.length);
  };

  const navigateTo = (link) => {
    navigate(link);
  };

  const visibleStories = [
    stories[(currentIndex - 1 + stories.length) % stories.length],
    stories[currentIndex],
    stories[(currentIndex + 1) % stories.length],
  ];

  return (
    <div className="brand-landing">
      <div className="box box--dark">
        {/* Brand Introduction Section */}
        <h1>Empowering Digital Innovation since 2015</h1>
        <p><center>
          Today, Make Europe GmbH is an independent creator of software and AI solutions.
          <br></br>Our name originates from the Maker, DIY, and open-source movements. 
          <br></br>We are based in the heart of Europe.
          </center></p>

        {/* Carousel Section */}
        <div className="carousel-container">
          <div className="carousel-3d">
            {visibleStories.map((story, index) => (
              <div
                key={story.year}
                className={`carousel-card-3d ${
                  index === 1 ? 'active' : ''
                } ${index === 0 ? 'previous' : ''} ${
                  index === 2 ? 'next' : ''
                }`}
              >
                <img
                  src={story.img}
                  alt={story.title}
                  className="product-image"
                />
                <h3>{story.year}</h3>
                <p>{story.title}</p>
                <button
                  className="cta-button"
                  onClick={() => navigateTo(story.link)}
                >
                  Learn More
                </button>
              </div>
            ))}
          </div>
          <button className="carousel-arrow right-arrow" onClick={handleNext}>
            &gt;
          </button>
        </div>
        <h2>
          <i>New!</i><br></br> You can now <b>license or buy the <br></br>branding rights</b> to use <br></br> "MAKE
          Europe"
        </h2>
      </div>
    </div>
  );
};

export default Brand;
