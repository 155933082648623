import React from 'react';
import '../styles/productstyles/product1.css'; // Reuse the existing CSS file
import ProductTabs from '../components/ProductTabs'; // Import the ProductTabs component
import FloatingMenu from '../components/FloatingMenu'; // Import the FloatingMenu component
import productImage from '../assets/shubhamdhage.png'; // Use a relevant image for this product
import ethDenverLogo from '../assets/ethdenverlogo.png'; // Add correct path for logos
import celoLogo from '../assets/celologo.png';
import polkadotLogo from '../assets/polkadot.png';
import coloradoLogo from '../assets/sealcolorado.png';

const ProductLanding4 = () => {
  return (
    <div className="product-landing">
      {/* Render ProductTabs for desktop users */}
      <ProductTabs />

      {/* Render FloatingMenu for mobile users */}
      <FloatingMenu />

      {/* Hero Section */}
      <div className="box box--dark">
        <img
          src={productImage}
          alt="Web 3 and dApps Development"
          className="brand-hero-image-300"
        />
        <h1>Decentralized Apps for the Web3</h1>
        <p><center>
          We develop community solutions on Bitcoin, Ethereum, or Layer 2.</center>
        </p>
      </div>

      {/* Services Section */}
      <div className="box box--light">
      <h2>Business Built on Blockchains</h2>
        <p><center>Empower your business with custom-built dApps and Web 3.</center></p>
        <p><center>Our team specializes in developing community-driven applications that are secure, scalable, and designed for the decentralized future.</center></p>
          <h2>Available Services</h2>
        <ul>
          <li>
            <strong>Custom dApp Development:</strong> Tailored decentralized applications that meet your needs.
          </li>
          <li>
            <strong>Secure and Audited Smart Contracts:</strong> Secure and audited smart contracts.
          </li>
          <li>
            <strong>Blockchain Integration:</strong> Expertise in Bitcoin, Ethereum, and Layer 1 blockchain networks.
          </li>
        </ul>
      </div>

      {/* ETHDenver 2022 Section */}
      <div className="box box--dark">
      <h2>Accolades</h2>
      <p style={{ textAlign: 'center' }}>
        Read more{' '}
     <a href="http://localhost:3000/brand/brandstory5">about Make Europe GmbH's international distinctions.</a>
      </p>

        <div className="ethdenver-logos">
          <div className="ethdenver-logo">
            <img src={ethDenverLogo} alt="ETHDenver Mobile Track" />
          </div>
          <div className="ethdenver-logo">
            <img src={celoLogo} alt="Layer1 | Celo" />
          </div>
          <div className="ethdenver-logo">
            <img src={polkadotLogo} alt="Parachains | Web3 Foundation" />
          </div>
          <div className="ethdenver-logo">
            <img src={coloradoLogo} alt="ColoradoJam | State of Colorado" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLanding4;
