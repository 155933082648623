// src/components/ProductTabs.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/ProductTabs.css'; // Add appropriate styles

const ProductTabs = () => {
  return (
    <div className="product-tabs">
      <NavLink to="/products/product1" activeClassName="active-tab">Generative AI</NavLink>
      <NavLink to="/products/product2" activeClassName="active-tab">BP Automation</NavLink>
      <NavLink to="/products/product3" activeClassName="active-tab">Mobile Apps</NavLink>
      <NavLink to="/products/product4" activeClassName="active-tab">Web3 Apps</NavLink>
    </div>
  );
};

export default ProductTabs;
