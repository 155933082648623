import React, { useRef } from 'react';
import '../styles/brandstories/BrandStory2.css';
import brandImage from '../assets/MRM17.png';
import makeKong17 from '../assets/makekong17.png';
import inMoov from '../assets/inmoov.png';
import spaceLasers from '../assets/spacelasers.png';
import upcycling from '../assets/upcycling.png';
import drone from '../assets/drone.png';

const BrandStory2 = () => {
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  return (
    <div className="brand-landing">
      {/* Hero Section */}
      <div className="box box--dark">
        <img src={brandImage} alt="Webweek Frankfurt" className="brand-hero-image-300" />
        <h1>Webweek: Connecting a Digital City</h1>
        <p><center>Empowering digital pioneers and innovators across the Rhein-Main region.</center></p>
      </div>

      {/* Webweek Summary Section */}
      <div className="box box--light">
        <h2>What Was Webweek?</h2>
        <p>
          Webweek? That sound is rining a bell when you talk to tech workers in and around Frankfurt, Germany. The annual event series connected over 50,000 early adopters and digital enthusiasts across the Rhein-Main region, representing a significant percentage of the 6.5 million people living in the area.
        </p>
        <h2>What Was Make Rhein-Main?</h2>
        <p>
          Make Rhein-Main was a Community of Tech Builders of both software and hardware. We welcomed The Real Life Guys and even guests like Gael Langevin, the inventor of the InMoov open-source project.
        </p>
        <h2>What Was Agile Konferenz?</h2>
        <p>
          Agile Konferenz was a successful in-person conference for agile-minded professionals.
        </p>
      </div>

      {/* Image Carousel Section */}
      <div className="box box--dark">
        <h2>Highlights from Webweek</h2>
        <div className="image-carousel">
          <button className="carousel-button left" onClick={scrollLeft}>
            &lt;
          </button>
          <div className="carousel-16-9" ref={carouselRef}>
            <div className="carousel-item">
              <img src={makeKong17} alt="Make Kong 2017" className="carousel-image" />
              <div className="carousel-caption">
                <p>
                  We called for makers, dreamers, and DIY folks, and they came in droves to see one Android, one DIY in-person laser shooter, and two dozen groups building.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={inMoov} alt="InMoov Project" className="carousel-image" />
              <div className="carousel-caption">
                <p>The InMoov project by Gael Langevin is a full-body android you can 3D print, open-source.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={spaceLasers} alt="Space'n'Laser" className="carousel-image" />
              <div className="carousel-caption">
                <p>Space'n'Laser was an installation by Makerspace Frankfurt. Players had to protect The White House from an alien invasion.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={upcycling} alt="Upcycling" className="carousel-image" />
              <div className="carousel-caption">
                <p>We think out of the box.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={drone} alt="Drones" className="carousel-image" />
              <div className="carousel-caption">
                <p>Innovative use of drones in urban environments.</p>
              </div>
            </div>
          </div>
          <button className="carousel-button right" onClick={scrollRight}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandStory2;
