import React from 'react';
import '../styles/brandstories/BrandStory4.css'; // Use the updated CSS file for BrandStory4
import tickethImage from '../assets/ticketh_brand.png'; // Add a relevant image for ticketh
import culturalSummerImage from '../assets/corona.png'; // Placeholder for the Cultural Summer events

const BrandStory4 = () => {
  return (
    <div className="brand-landing">
      {/* Hero Section */}
      <div className="box box--dark">
        <img
          src={tickethImage}
          alt="Ticketh Web3 Ticketing Software"
          className="brand-hero-image-300"
        />
        <h1>Europe's First Web3 Tickets: "tickETH"</h1>
        <p><center>Our tech helped people meet and enjoy music during the pandemic!</center></p>
      </div>

      {/* Main Content Section */}
      <div className="box box--light">
        <h2>Introducing Ticketh</h2>
        <p>
          In 2018, we began our journey with "ticketh" in the EintrachtTech Accelerator, offering a
          Web3 alternative to traditional ticketing platforms like Eventbrite.
        </p>
        <h2>Innovating Through the Pandemic</h2>
        <p>
          When the pandemic struck in 2020, "ticketh" was ready to help: Partnering with the city of
          Frankfurt, we developed a privacy-secure entry system for the Tanzhaus West, allowing over
          3,000 guests to safely attend 12 different open-air events during the Cultural Summer.
        </p>
        <img
          src={culturalSummerImage}
          alt="Cultural Summer Event"
          className="story-image"
        />
      </div>

      {/* Future Vision and Call to Action Section */}
      <div className="box box--dark">
      <h2>Future of Payments Award 2022</h2>
        <p>
          Make Europe's NFT platform was awarded the "Future of Payments" Award by the Celo Foundation, San Francisco. <br></br><br></br> Our platform is now compatible with digital wallets and ready for the digital euro,
          ensuring a secure and advanced event management experience.
        </p>
        <p>
        <h2>Celo, Polygon, Base</h2>
        </p>
        <p>
          Make Europe joined the Celo Accelerator and launched its emission-free NFT ticketing platform on their Layer-2. Clones existed on Polygon and Base.
        </p>
      </div>
    </div>
  );
};

export default BrandStory4;
