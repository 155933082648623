import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import BSFZSeal from '../assets/BSFZ_Siegel_MAKE_Europe.png';  // Importing the BSFZ logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-bsfsz">
        <img src={BSFZSeal} alt="BSFZ Seal" width="111" height="110" />
        <h3>Make Europe GmbH is the 2024 recipient of the <br></br>Federal BSFZ Seal for Innovation Competence in Germany.</h3>
      </div>
      <div className="footer-links">
        <Link to="/" className="footer-link">Home</Link>
        <Link to="/brand" className="footer-link">Brand</Link>
        <Link to="/product" className="footer-link">Products</Link>
        <a href="mailto:roman@make-europe.com" className="footer-link">Contact Make Europe</a>
        <Link to="/agb" className="footer-link">AGB + Impressum</Link>
      </div>
      <p>&copy; 2024 Make Europe GmbH,<br></br> all rights reserved.</p>
      <div className="social-icons">
        {/* Add social media icons or links here */}
      </div>
      <div className="footer-notice">
        This site does NOT use cookies or collect data.<br></br>
        Your emails to us will be saved and are considered as consent for mutual contact. Our website contains links to external websites of third parties over whose content we have no influence.
      </div>
    </footer>
  );
};

export default Footer;
