import React from 'react';
import '../styles/brandstories/BrandStory1.css';
import brandImage from '../assets/RRQ.png';
import rrqRulesHead from '../assets/rrqruelshead.png';
import rrqRules3 from '../assets/rrqruels3.png';

const BrandStory1 = () => {
  return (
    <div className="brand-landing">
      {/* Hero Section */}
      <div className="box box--dark">
        <img src={brandImage} alt="Make Europe GmbH" className="brand-hero-image-300" />
        <h1>The Computer Classics Card Game</h1>
        <p><center>
          Our evergreen product is the Computer Classics Card Game! Available on <a href="https://www.amazon.de/Retro-Rechner-Quartett-QUAI028-Strategiespiel/dp/B0771127CD">Amazon</a>
          </center></p>
      </div>

      {/* Brand Story Section */}
      <div className="box box--light">
        <h2>
          The "Hall of Digital Fame"
        </h2>
        <h4>
         It’s a fun, strategic card game that includes all cult computers.
        </h4>
        <div className="intro-text">
          <img src={rrqRulesHead} alt="RRQ Rules Head" className="story-image" />
          <p>
            The Computer Classics Card Game was developed with several German museums, including Berlin's Computerspielemuseum and Frankfurt's Digital Retro Park.
          </p>
          <p>
            It features 32 digital milestones from a time long before smartphones.
          </p>
          <p>
            A real cultural connector: We shipped this game to almost every country on the planet!
          </p>
          <br></br>
          <h2>Who's Part of This Selection?</h2>
          <ul>
            <li>
              <strong>Olivetti Programma 101, 1965:</strong> Calculated Apollo 11's routes to the moon.
            </li>
            <li>
              <strong>Nokia Communicator, 2001:</strong> The first smartphone.
            </li>
            <li>
              <strong>Apple Newton, 1993:</strong> The first iPad.
            </li>
            <li>
              <strong>Commodore C64, 1982:</strong> The best-selling personal computer of all time.
            </li>
            <li>
              <strong>Amiga 500, 1987:</strong> Known for its graphics and sound capabilities.
            </li>
            <li>
              <strong>Schneider/Amstrad, 1984:</strong> Popular personal computer in Europe during the 80s.
            </li>
          </ul>
        </div>
      </div>

      {/* Game Rules Section */}
      <div className="box box--dark">
        <h2>Game Rules</h2>
        <p>
          Each card features the specs of a classic computer, turning these technical details into game mechanics. The Computer Classics Card Game is well-balanced for up to eight players, easy to learn, and entertaining for all ages.
          <br />
          A round of four players typically takes 10-15 minutes, making it a quick and enjoyable way to appreciate the history of computing.
        </p>
        <img src={rrqRules3} alt="RRQ Rules" className="story-image" />
        <table className="details-table">
          <tbody>
          <center>
            <tr>
              <td>
                <strong>Year of Launch:</strong>
              </td>
              <td>The older, the better.</td>
            </tr>
            <tr>
              <td>
                <strong>CPU Processor Speed:</strong>
              </td>
              <td>The faster, the better.</td>
            </tr>
            <tr>
              <td>
                <strong>RAM Memory:</strong>
              </td>
              <td>The higher, the better.</td>
            </tr>
            <tr>
              <td>
                <strong>Weight:</strong>
              </td>
              <td>The heavier, the better.</td>
            </tr>
            <tr>
              <td>
                <strong>Price at Launch:</strong>
              </td>
              <td>The higher, the better.</td>
            </tr>
            </center>
          </tbody>
        </table>
        <h2>Strategic Gameplay</h2>
        <p>
          The most vintage computer can be a valuable card. For example, the super-heavy Commodore PET 2001 from 1977 would win over the light Apple Newton (aka the iPad zero) from 1993 in terms of age and weight.
        </p>
        <p>
          With superior specs like speed and memory, the "young timers" have an edge over the older ones, adding a layer of strategy to the game.
        </p>
      </div>
    </div>
  );
};

export default BrandStory1;
