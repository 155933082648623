import React from 'react';
import '../styles/productstyles/product1.css'; // Import the existing CSS file
import ProductTabs from '../components/ProductTabs';
import FloatingMenu from '../components/FloatingMenu';
import productImage from '../assets/nilskeesmekers.png';
import safteyImage from '../assets/saftey.png';
import pizzaImage from '../assets/pizza.png';
import hopsImage from '../assets/hops.png';

const ProductLanding2 = () => {
  return (
    <div className="product-landing">
      {/* Render ProductTabs for desktop users */}
      <ProductTabs />

      {/* Render FloatingMenu for mobile users */}
      <FloatingMenu />

      {/* Hero Section */}
      <div className="box box--dark">
        <img src={productImage} alt="Productivity Booster" className="brand-hero-image-300" />
        <h1>Productivity Booster</h1>
        <p><center>Business Process Automation powered by AI.</center></p>
      </div>

      {/* Services Section */}
      <div className="box box--light">
      <h2>Use Your Labor More Efficiently</h2>
      <p>
          Our AI-powered automation tools, known as the Productivity Booster, can help increase your productivity by at least 30%, with some users reporting up to a 300% improvement.
          <br></br><br></br>Streamline your operations, reduce human error, and focus on what really matters.
        </p>
        <h2>Available Services</h2>
        <ul>
          <li><strong>Task Automation:</strong> Automate repetitive tasks and free up valuable time.</li>
          <li><strong>Process Optimization:</strong> Use AI to analyze and optimize your business processes.</li>
          <li><strong>Custom Automation Solutions:</strong> Tailored automation to meet your specific business needs.</li>
        </ul>
      </div>

      {/* Company Specialties Section */}
      <div className="box box--dark">
        <h2>Our Specialties in Business Automation</h2>
        <p><center>We provide cutting-edge solutions tailored to boost your productivity:</center></p>
        <div className="specialties-logos">
          <div className="specialty">
            <img src={safteyImage} alt="Task Automation" />
            <p><center>Task Automation</center></p>
          </div>
          <div className="specialty">
            <img src={hopsImage} alt="Process Optimization" />
            <p><center>BPA - Process Optimization</center></p>
          </div>
          <div className="specialty">
            <img src={pizzaImage} alt="AI Integration" />
            <p><center>AI Integration + Automation</center></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLanding2;
