import React from 'react';
import '../styles/productstyles/product1.css'; // Reuse the existing CSS file
import ProductTabs from '../components/ProductTabs';
import FloatingMenu from '../components/FloatingMenu';
import productImage from '../assets/jameswiseman.png';
import eventTechImage from '../assets/eventtech.png';
import aiMarketingImage from '../assets/aimarketing.png';
import onlinePaymentsImage from '../assets/onlinepayments.png';

const ProductLanding3 = () => {
  return (
    <div className="product-landing">
      {/* Render ProductTabs for desktop users */}
      <ProductTabs />

      {/* Render FloatingMenu for mobile users */}
      <FloatingMenu />

      {/* Hero Section */}
      <div className="box box--dark">
        <img
          src={productImage}
          alt="Web and Mobile App Development"
          className="brand-hero-image-300"
        />
        <h1>Web & Mobile App Development</h1>
        <p>
        <center>AI-Driven Solutions for Modern Businesses</center>
        </p>
      </div>

      {/* Services Section */}
      <div className="box box--light">
      <h2>Business Built on Clouds</h2>
      <p><center>
      Empower your business with custom-built web and mobile applications that incorporate AI for smarter, more intuitive user experiences.<br></br><br></br>
          Our team specializes in developing applications that are not only robust and scalable but also equipped with the latest AI technologies to give you a competitive edge.</center></p>
          <h2>Available Services</h2>
        <ul>
          <li>
            <strong>Custom Web Development:</strong> Build responsive, scalable web applications tailored to your business needs.
          </li>
          <li>
            <strong>Mobile App Development:</strong> Develop cross-platform mobile apps with AI-driven features.
          </li>
          <li>
            <strong>AI Integration:</strong> Enhance your apps with AI-powered features like chatbots, predictive analytics, and more.
          </li>
        </ul>
      </div>

      {/* Company Specialties Section */}
      <div className="box box--dark">
        <h2>Our Specialties in Web & Mobile App Development</h2>
        <p><center>We excel in delivering innovative solutions tailored to your business needs:</center></p>
        <div className="specialties-logos">
          <div className="specialty">
            <img src={eventTechImage} alt="Event Ticketing" />
            <p>Event Ticketing</p>
          </div>
          <div className="specialty">
            <img src={aiMarketingImage} alt="AI Integrations and Marketing" />
            <p>AI Integrations and Marketing</p>
          </div>
          <div className="specialty">
            <img src={onlinePaymentsImage} alt="Credit Card and Merchant Payments" />
            <p>Credit Card and Merchant Payments</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLanding3;
