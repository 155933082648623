// src/components/FloatingMenu.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/FloatingMenu.css'; // Add appropriate styles

const FloatingMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="floating-menu">
      <button className="floating-button" onClick={toggleMenu}>
        ☰
      </button>
      {isOpen && (
        <div className="floating-menu-options">
          <Link to="/products/product1">Generative AI</Link>
          <Link to="/products/product2">BP Automation</Link>
          <Link to="/products/product3">Mobile Apps</Link>
          <Link to="/products/product4">Web3 Apps</Link>
        </div>
      )}
    </div>
  );
};

export default FloatingMenu;
