import React from 'react';
import '../styles/brandstories/BrandStory3.css';
import digitalDinosImage from '../assets/Commodorec64.jpg';
import openseaImage from '../assets/opensea.png';

const BrandStory3 = () => {
  return (
    <div className="brand-landing">
      {/* Hero Section */}
      <div className="box box--dark">
        <img src={digitalDinosImage} alt="Digital Dinos Collection" className="brand-hero-image-300" />
        <h2>Immutable: 2019 Ethereum Mainnet NFT Collection</h2>
        <p><center>
          <em><strong>Incredibly rare!</strong></em>{' '}
          <a href="https://opensea.io/assets/ethereum/0x157b9d66f864b2c2b8228efd4ad92b38410e4fa6/3" className="highlight-link">
            There are only 3 Olivetti Programma 101 ever minted.
          </a>
          </center>
        </p>
      </div>

      {/* Historical Significance Section */}
      <div className="box box--light">
        <h2>What are Digital Dinos Vintage NFTs ($DINO) from 2019?</h2>
        <p>
          During the holiday season of the blockchain year 2019, each morning, a new Digital Dino ($DINO) hatched as a free give-away token for the first 50 claimers.
          A total of 2053 NFTs were minted in this unique collection. These NFTs represent a legendary retro computer that has left its mark on history.
        </p>
        <div className="opensea-link">
          <a href="https://opensea.io/collection/digitaldinos" target="_blank" rel="noopener noreferrer">
            <img src={openseaImage} alt="OpenSea Logo" className="opensea-image" />
          </a>
        </div>
        <p>
          Add a piece of digital history to your collection:{' '}
          <a href="https://opensea.io/collection/digitaldinos" target="_blank" rel="noopener noreferrer">
            Digital Dinos on OpenSea
          </a>
        </p>
      </div>

      {/* Collection Details Section */}
      <div className="box box--dark">
        <h2>Collection Details</h2>
        <table className="details-table">
          <tbody>
            <center>
            <tr>
              <td><strong>Chain:</strong></td>
              <td>Ethereum</td>
            </tr>
            <tr>
              <td><strong>Date:</strong></td>
              <td>Minted in 2019</td>
            </tr>
            <tr>
              <td><strong>Total NFTs:</strong></td>
              <td>2053</td>
            </tr>
            <tr>
              <td><strong>Owners:</strong></td>
              <td>85</td>
            </tr>
            <tr>
              <td><strong>Token ID:</strong></td>
              <td>3</td>
            </tr>
            <tr>
              <td><strong>ID:</strong></td>
              <td>DINO</td>
            </tr>
            </center>
          </tbody>
        </table>
        <p className="details-text">
          Each Dino represents a piece of computing history, like the iconic Commodore C64, the world’s best-selling personal computer.
          This collection captures the nostalgia of the digital stone age, reminding us of the milestones that paved the way for today's digital world.
        </p>
        <h2>Start Your Own NFT Collection with Us!</h2>
        <p>
          At Make Europe, we offer a large selection of{' '}
          <a href="http://localhost:3000/products/product4">
            White Label Web3 Apps by Make Europe
          </a>.
        </p>
      </div>
    </div>
  );
};

export default BrandStory3;
