import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/brandstories/BrandStory1.css'; // Use the common CSS file for consistency
import '../styles/ProductSection.css'; // Additional CSS for the carousel

import Logo from '../assets/make300.png';
import GenerativeAIImage from '../assets/Lisai.png';
import BPAutomationImage from '../assets/nilskeesmekers.png';
import MobileAppsImage from '../assets/jameswiseman.png';
import Web3AppsImage from '../assets/shubhamdhage.png';

const ProductSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const products = [
    { name: 'Make Europe - Our Brand', img: Logo, link: '/Brand' },
    {
      name: 'Top Seller! Lisa Luckas (Gen AI)',
      img: GenerativeAIImage,
      link: '/products/product1',
    },
    {
      name: 'Productivity Booster! AI Business Process Automation',
      img: BPAutomationImage,
      link: '/products/product2',
    },
    {
      name: 'Mobile First! Web+App Development for Your Idea',
      img: MobileAppsImage,
      link: '/products/product3',
    },
    {
      name: 'White-Label! Our Leading Web3 Apps For Your Business',
      img: Web3AppsImage,
      link: '/products/product4',
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const navigateTo = (link) => {
    navigate(link);
  };

  const visibleProducts = [
    products[(currentIndex - 1 + products.length) % products.length],
    products[currentIndex],
    products[(currentIndex + 1) % products.length],
  ];

  return (
    <div className="brand-landing">
      {/* Hero Section */}
      <div className="box box--dark product-section">
        <h1>Innovative Software and AI Solutions</h1>
        <div className="carousel-container">
          <div className="carousel-3d">
            {visibleProducts.map((product, index) => (
              <div
                key={product.name}
                className={`carousel-card-3d ${
                  index === 1 ? 'active' : ''
                } ${index === 0 ? 'previous' : ''} ${
                  index === 2 ? 'next' : ''
                }`}
              >
                <img
                  src={product.img}
                  alt={product.name}
                  className="product-image"
                />
                <h3>{product.name}</h3>
                <button
                  className="cta-button"
                  onClick={() => navigateTo(product.link)}
                >
                  Learn More
                </button>
              </div>
            ))}
          </div>
          <button className="carousel-arrow right-arrow" onClick={handleNext}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
