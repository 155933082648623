import React from 'react';
import '../styles/AGB.css'; // Adjust the path if necessary
import make800 from '../assets/make800.png'; // Ensure the path is correct

const AGB = () => {
  return (
    <div className="agb-page">
      {/* Include the make800 image */}
      <img src={make800} alt="Make Europe GmbH" className="brand-hero-image" />

      <div className="agb-content">
        <h1>Allgemeine Geschäftsbedingungen (AGB) der MAKE Europe GmbH</h1>
        <p className="update-date">- Aktualisierte Version vom 5.9.2021 -</p>

        {/* Company Information */}
        <div className="company-info">
          <p>
            <strong>Make Europe GmbH</strong><br />
            Im Alten Roth 7<br />
            36039 Dietershan<br />
            <strong>Niederlassung Rhein-Main:</strong><br />
            Sternstr. 23<br />
            60318 Frankfurt am Main
          </p>
          <p>
            <strong>Steuernummer:</strong> 018 239 02547<br />
            <strong>HRB:</strong> 6756<br />
            <strong>Geschäftsführer:</strong> Roman Keßler
          </p>
        </div>

        {/* Table of Contents */}
        <h2>AGB INHALTSVERZEICHNIS:</h2>
        <ol className="table-of-contents">
          <li>1. GEGENSTAND UND UMFANG</li>
          <li>2. LEISTUNGEN DES AUFTRAGNEHMERS</li>
          <li>3. SOFTWAREENTWICKLUNG</li>
          <li>4. MEDIA- und BERATUNGSDIENSTLEISTUNGEN</li>
          <li>5. ELEKTRONISCHE PLATTFORMEN</li>
          <li>6. FRISTEN</li>
          <li>7. MITWIRKUNGSPFLICHTEN DES AUFTRAGGEBERS</li>
          <li>8. RECHTE AN SOFTWAREENTWICKLUNGEN UND SONSTIGEN ERGEBNISSEN</li>
          <li>9. VERGÜTUNG, PREISE, ZAHLUNGSBEDINGUNGEN</li>
          <li>10. ERSTATTUNG VON NEBENKOSTEN</li>
          <li>11. MÄNGELHAFTUNG</li>
          <li>12. HAFTUNG</li>
          <li>13. LIEFERUNG UND ABNAHME</li>
          <li>14. DATENSCHUTZ, GEHEIMHALTUNG UND SICHERHEIT</li>
          <li>15. UNABHÄNGIGE VERTRAGSPARTNER</li>
          <li>16. TEXTFORM</li>
          <li>17. SCHLUSSBESTIMMUNGEN</li>
        </ol>

        {/* AGB Sections */}
        <div className="agb-sections">
          {/* Section 1 */}
          <h2>1. GEGENSTAND UND UMFANG</h2>
          <p>
          1.1 Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für die Erbringung von IT-, Media- und Beratungsdienstleistungen sowie für die Entwicklung, Implementierung und Überlassung von Software durch die MAKE Europe GmbH (nachfolgend „Auftragnehmer“).
Wo nicht anders vereinbart, gelten diese allgemeinen Geschäftsbedingungen ebenfalls für online geschlossene Verträge; insbesondere auch auf vom Auftragnehmer selbst online angebotenen elektronischen Plattformen, wo unter anderem Wertgutscheine, Tickets und weitere Digitale Güter und Dienste registriert, vermittelt, vermarktet oder verkauft werden.

1.2 Die von dem Auftragnehmer geschuldeten Leistungen werden einzelvertraglich vereinbart. Einzelverträge kommen mit schriftlicher Annahme eines dem Auftraggeber vom Auftragnehmer unterbreiteten schriftlichen Angebots oder durch Abschluss eines durch beide Parteien unterzeichneten Vertrags zustande. Im Falle eines Widerspruchs zwischen einer der in diesen Allgemeinen Geschäftsbedingungen aufgeführten Bestimmungen und einer Bestimmung im Einzelvertrag, geht die Bestimmung aus dem Einzelvertrag vor.

1.3. Ein Auftrag gilt als zustande gekommen, sofern eine schriftliche Beauftragung vorliegt. Eine Beauftragung gilt, sofern nicht anders einzelvertraglich vereinbart, für drei Kalendermonate. Sofern nicht anders schriftlich vereinbart, verlängert sich die Beauftragung automatisch zum Monatsende um drei Monate.
          </p>

          {/* Section 2 */}
          <h2>2. LEISTUNGEN DES AUFTRAGNEHMERS</h2>
          <p>
          2.1 Der Auftragnehmer verpflichtet sich, die gemäß Einzelvertrag geschuldeten Leistungen unter Berücksichtigung des Stands der Technik zu erbringen.

2.2 Der Auftragnehmer bestimmt grundsätzlich Ort und Zeit der Leistung selbst. Jedoch sind zeitliche, räumliche und fachliche Anforderungen zu beachten, soweit sie sich aus der Leistungsbeschreibung ergeben oder in zwischen den Parteien abgestimmten Termin- oder Leistungsplänen enthalten oder zur Erreichung des Zwecks der Beauftragung erforderlich sind. Für die zur Erbringung der Leistungen notwendigen Arbeitsmittel ist der Auftragnehmer selbst verantwortlich, soweit nicht anders vereinbart.

2.3 Der Auftragnehmer darf zur Erbringung der Leistungen qualifizierte Subunternehmer einsetzen. Der Auftragnehmer ist für die von seinen Subunternehmern erbrachten Leistungen wie für eigene Leistungen verantwortlich.

2.4 Die zur Erbringung der Leistungen eingesetzten Personen müssen vereinbarungsgemäß, unabhängig davon jedoch mindestens dem Vertragszweck und der Aufgabenstellung entsprechend qualifiziert sein. Der Auftragnehmer darf zur Vertragserfüllung eingesetzte Personen unter angemessener Berücksichtigung der Interessen des Auftraggebers durch qualifizierte Ersatzpersonen auswechseln. Eine Ersatzperson gilt nur dann als qualifiziert, wenn sie mindestens über die vertraglich vorausgesetzte Eignung verfügt.

2.5 Der Auftraggeber kann mit Begründung den Austausch einer vom Auftragnehmer zur Vertragserfüllung eingesetzten Person verlangen, wenn diese erheblich gegen vertragliche Pflichten verstoßen hat. In diesen Fällen gilt Ziffer 2.4 entsprechend.
          </p>

          {/* Continue with the rest of the sections */}
          {/* Section 3 */}
          <h2>3. SOFTWAREENTWICKLUNG</h2>
          <p>Für den Fall, dass der Auftragnehmer dem Auftraggeber die Entwicklung von Software schuldet, gelten ergänzend folgenden Bestimmungen:
          <br /><br />
              3.1 Der Auftragnehmer ist berechtigt, bei der Softwareentwicklung von Dritten entwickelte Software, einschließlich Open Source Software, Freeware, Shareware und Public Domain Software, bearbeitet und unbearbeitet einzusetzen und in die geschuldeten Tätigkeitsergebnisse zu integrieren.
              <br /><br />
              3.2 Soweit nicht einzelvertraglich anders bestimmt, umfasst die Lieferung der geschuldeten Software nicht die Lieferung des Quellcodes und ebensowenig der Quell- oder Header-Dateien und auch nicht des kompilierten Objektcodes und der ausführbaren Dateien der Software.
              <br /><br />
              3.3 Wo die Übertragung des Quellcodes (oder von Teilen dessen) zur Nutzung der Vertragssoftware einzelvertraglich vereinbart wurde, muss diese so in Textform beschrieben und dokumentiert sein, dass ein geübter Programmierer in der Lage ist, den Quellcode zu verstehen, zu bearbeiten und weiterzuentwickeln. Die Dokumentation kann durch Inline-Kommentare im Quelltext erfolgen. Weitere Dokumentationen schuldet der Auftragnehmer nicht.
              <br /><br />
              3.4 Soweit im Einzelvertrag nicht anders vereinbart, werden Softwareentwicklungen als agile Projekte mit einer verbindlichen Lenkungsform („Governance“) und zeitlich festgelegten Entwicklungsintervallen (Sprints) durchgeführt. Einzelheiten der Zusammenarbeit im agilen Projekt ergeben sich aus dem Einzelvertrag.
          </p>

          <h2>4. MEDIA- und BERATUNGSDIENSTLEISTUNGEN</h2>
          <p>
            Für den Fall, dass der Auftragnehmer dem Auftraggeber die Entwicklung von Media- und Beratungsdienstleistungen schuldet, gelten ergänzend folgenden Bestimmungen:
            <br /><br />
            4.1 Die Vertragspartner stellen sich gegenseitig bereits existierende Inhalte zur Bewerbung und Durchführung der Kommunikationsmaßnahmen zur Verfügung, ohne diese gesondert zu bepreisen oder deren Verwendung zu beschränken. Sie achten dabei insbesondere darauf, dass die Rechte Dritter an diesen Inhalten nicht verletzt werden und dass die Inhalte nicht missbräuchlich zum Nachteil der Vertragspartner verwendet werden.
            <br /><br />
            4.2. Für alle Inhalte, die im Rahmen der Media- und Beratungsdienstleistungen  unter direkter Einbindung der Mitarbeitenden von des Auftraggebers oder Dritter erzeugt werden, stellt der Auftraggeber den Auftragnehmer von sämtlichen Ansprüchen Dritter frei und steht dafür ein.
            <br /><br />
            4.3. Für sämtliche zur Verfügung gestellten Geschäftsgeheimnisse oder Insider-Wissen haftet der Auftraggeber, der den Auftragnehmer schon vor der Mitteilungs solche Geheimnisse schriftlich anzeigt und deren Nutzung schriftlich regelt. Näheres kann durch ein zusätzliches Non-Disclosure-Agreement (NDA) schriftlich geregelt werden.
          </p>
          <h2>5. ELEKTRONISCHE PLATTFORMEN</h2>
          <p>Für den Fall, dass der Auftragnehmer dem Auftraggeber den Einzelvertrag auf einer der vom Auftragnehmer selbst betriebenen elektronischen Plattformen schließen, gelten für die Registrierung, Vermittlung, Vermarktung oder Zustellung von Wertgutscheinen, Tickets und weitere Digitale Güter und Services gelten ergänzend folgenden Bestimmungen:
            Die nachstehenden Nutzungsbedingungen für elektronische Plattformen des Auftragnehmers enthalten insbesondere die grundlegenden Regeln für die Vermittlung von Tickets zu verschiedenen Veranstaltungen und die Abwicklung des Verkaufsprozesses einschließlich Versand und gelten ab ihrer Einbeziehung für den Ticketkauf durch den Endkunden. Abweichende Vertragsbedingungen des Kunden werden nicht anerkannt, es sei denn, der Auftragnehmer stimmt ihrer Geltung ausdrücklich schriftlich zu.
            <br /><br />
            5.1. Bei der Vermittlung eines Kaufs von Wertgutscheinen, Tickets und weiterer Digitaler Güter und Services über diese elektronische Plattformen kommt ein Vertrag hinsichtlich der Erfüllung ausschließlich zwischen dem Kunden und dem jeweiligen Anbieter des Angebots zustande.
            <br /><br />
            5.2. Die Erfüllung der über diese elektronische Plattformen beworbenen Angebote mit allen Rechten und Pflichten obliegt allein dem jeweiligen Anbieter.
            <br /><br />
            5.3. Der Online-Zahler wird ausdrücklich darauf hingewiesen, dass der Anbieter darüber hinaus weitere grundlegende Regeln für die Teilnahme an seinen Veranstaltungen und Events in eigenen Allgemeinen Geschäftsbedingungen stellen kann, die für eine Bestellung im Rahmen des Bestellprozesses ebenfalls akzeptiert werden müssen.
            <br /><br />
            5.4. Durch die im Rahmen des Bestellvorgangs zu aktivierende Check-Box erklärt der Kunde sich ausdrücklich mit den Nutzungsbedingungen der elektronischen Plattformen einverstanden. Sofern der Anbieter dem Vertragsverhältnis darüber hinaus
            weitere Allgemeinen Geschäftsbedingungen zugrunde legt, findet der Kunde im Rahmen des Bestellvorgangs hierfür eine eigene zu aktivierende Check-Box, die er ebenfalls anklicken muss. Ohne das Bestätigen der vorhandenen aktiven Checkboxen kann der Kunde über diese elektronischen Plattformen nichts kaufen.
            <br /><br />
            5.5. Der Erwerb der Tickets auf elektronischen Plattformen ist natürlichen oder juristischen Personen gestattet, natürlichen Personen allerdings nur, wenn diese unbeschränkt geschäftsfähig sind.  Der Auftragnehmer behält sich vor, einen Nachweis über die unbeschränkte Geschäftsfähigkeit jederzeit anzufordern.
            <br /><br />
            5.6. Der Kunde einer elektronischen Plattform ist Verbraucher, soweit der Zweck der georderten Lieferungen und Leistungen nicht seiner gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden kann. Dagegen ist Unternehmer einer elektronischen Plattform jede natürliche, juristische Person oder rechtsfähige Personengesellschaft, die beim Abschluss des Vertrags in Ausübung ihrer gewerblichen oder selbstständigen beruflichen Tätigkeit handelt.
            <br /><br />
            5.7. Der Auftragnehmer bietet auf den elektronischen Plattformen unter anderem Veranstaltungen und Konzertbesuche von verschiedenen Veranstaltern an. Mit dem Erwerb eines Tickets ist der Kunde berechtigt, an der von ihm ausgewählten Veranstaltung teilzunehmen. Für die Zulassung und Durchführung der Teilnahme an der jeweils ausgewählten Veranstaltung ist allein der Veranstalter verantwortlich. Nähere Einzelheiten zu den angebotenen Veranstaltungen können Sie auf der Website des jeweiligen Veranstalters einsehen und abrufen.
            <br /><br />
            5.8. Der Auftragnehmer ist berechtigt, auf seinen elektronischen Plattformen den Verkauf von Tickets, Wertgutscheinen oder anderer Digitaler Güter mit den Endkunden zu verhandeln und entsprechende Verträge abzuschließen (Abschlussvollmacht). Darüber hinaus ist der Auftragnehmer berechtigt, Vertragsabschlüsse mit Endkunden, gleich aus welchem Grund, jederzeit abzulehnen. Ein Anspruch des Kunden auf den Erwerb eines und/oder mehrerer Tickets besteht grundsätzlich nicht.
            <br /><br />
            5.9. Der Kunde ist verpflichtet, bei einer Bestellung auf einer elektronischen Plattform wahrheitsgemäße und korrekte Angaben im Rahmen des Bestellvorgangs zu machen. Eine erfolgreiche Bestellung ist nur bei vollständigem Ausfüllen der Pflichtfelder möglich. Ändern sich nach der Bestellung die angegebenen Daten, so ist der Kunde verpflichtet, die Angaben umgehend dem Auftragnehmer mitzuteilen.
            <br /><br />
            5.10. Der Kunde ist berechtigt unter demselben oder unter verschiedenen Namen mehrere Bestellungen vorzunehmen und Tickets, Wertgutscheinen oder andere Digitaler Güter auf Dritte zu übertragen. Der Kunde ist jedoch nicht berechtigt, Tickets, Wertgutscheine oder andere Digitaler Güter und Dienste zu einem über dem Verkaufspreis der elektronischen Plattform liegenden Preis zu verkaufen.
            <br /><br />
            5.11. Der Auftragnehmer ist berechtigt, die registrierten Angaben des Kunden durch geeignete Maßnahmen zu überprüfen und nicht vollständige und/oder wahrheitsgemäße Bestellungen abzulehnen.
            <br /><br />
            5.12. Fristen für Verbraucher, die Tickets, Wertgutscheinen oder anderer Digitaler Güter auf elektronischen Plattformen des Auftragnehmers anbieten, regelt das deutsche Recht.
          </p>
          <h2>6. FRISTEN</h2>
          <p>
          Für den Fall, dass der Auftragnehmer dem Auftraggeber die Entwicklung oder die Erbringung von IT-, Media- und Beratungsdienstleistungen, die Entwicklung, Implementierung und Überlassung von Software schuldet  gelten ergänzend folgenden Bestimmungen:
          <br /><br />
          6.1. Fristen aus unternehmerischen Tätigkeiten wie Termine, einschließlich Meilensteine, sind nur verbindlich, wenn sie von beiden Parteien in Textform ausdrücklich als verbindlich vereinbart werden.
          <br /><br />
          6.2. Soweit im Einzelvertrag nicht anders bestimmt, können auf Seiten des Auftragnehmers verbindliche Termine nur durch die Geschäftsführung zugesagt werden.
          </p>
          <h2>7. MITWIRKUNGSPFLICHTEN DES AUFTRAGGEBERS</h2>
          <p>
          7.1. Der Auftraggeber unterstützt den Auftragnehmer bei der Erbringung der vertraglichen Leistungspflichten soweit zumutbar und erforderlich. Er wird dem Auftragnehmer rechtzeitig die erforderlichen Informationen und Unterlagen zur Verfügung stellen und den Mitarbeitenden des Auftragnehmers rechtzeitig Zugang zu seinen Räumlichkeiten und seiner informationstechnischen Infrastruktur, einschließlich seiner Entwicklungs-, Test- und Produktivumgebungen sowie Projektmanagementsystemen, gewähren, soweit dies zur Erbringung der Leistung erforderlich ist.
          <br /><br />
            7.2. Die ordnungsgemäße Datensicherung obliegt dem Auftraggeber, soweit die Datensicherung nicht Bestandteil der vom Auftragnehmer zu erbringenden Leistungen ist.
            <br /><br />
            7.3. Der Auftraggeber sorgt für die hinreichende Verfügbarkeit geeigneter Ansprechpartner und stellt sicher, dass Entscheidungen im Zusammenhang mit der Leistungserbringung unverzüglich getroffen werden.
            <br /><br />
            7.4. Sofern die Leistung ganz oder teilweise in den Räumlichkeiten des Auftraggebers erbracht werden muss, sorgt der Auftraggeber rechtzeitig für eine geeignete Arbeitsumgebung („Projektraum“) und stellt die erforderlichen Arbeitsmittel (Infrastruktur, Internetzugang, Software etc.) unentgeltlich zur Verfügung.
            <br /><br />
            7.5. Für den Fall, dass der Auftragnehmer dem Auftraggeber die Entwicklung von Media- und Beratungsdienstleistungen schuldet, weist der Auftragnehmer auf die oft zeitsensitiven Arbeitsweise von Medien, Journalisten und anderen Multiplikatoren in der Öffentlichkeitsarbeit hin. Der Auftraggeber akzeptiert und haftet dafür, dass zur erfolgreichen Durchführung der Kommunikationsmaßnahmen, Informationen und Freigaben innerhalb kurzer Fristen von wenigen Stunden oder Tagen eine wichtige Voraussetzung sind.
          </p>
          <h2>8. RECHTE AN SOFTWAREENTWICKLUNGEN UND SONSTIGEN ERGEBNISSEN</h2>
          <p>
          8.1. Die Parteien sind sich einig, dass vorbehaltlich einer vollständigen Zahlung der vom Auftraggeber geschuldeten Vergütung sämtliche Rechte an allen vom Auftragnehmer vertraglich geschuldeten Softwareentwicklungen und sonstigen vertraglich geschuldeten Ergebnissen (nachfolgend „Tätigkeitsergebnisse“) auf den Auftraggeber im größtmöglichen Umfang übertragen werden, soweit nachfolgend oder im Einzelvertrag nicht anders bestimmt. Die nachfolgenden Ziffern beschreiben den Umfang dieser Rechteübertragung im Einzelnen.
          <br /><br />
            8.2. Vorbehaltlich einer vollständigen Zahlung der vom Auftraggeber geschuldeten Vergütung überträgt der Auftragnehmer alle übertragungsfähigen Inhaberrechte an den Tätigkeitsergebnissen auf den Auftraggeber, jeweils mit Wirkung zum Zeitpunkt ihres Entstehens, ohne dass es eines weiteren Übertragungsakts bedarf.
            <br /><br />
            8.3. Wenn und soweit Tätigkeitsergebnisse nicht als solche übertragbar sind (insbesondere im Fall von Urheberrechten), räumt der Auftragnehmer dem Auftraggeber vorbehaltlich einer vollständigen Zahlung der vom Auftraggeber geschuldeten Vergütung sämtliche übertragungsfähigen Rechte an den Tätigkeitsergebnissen, insbesondere sämtliche urheberrechtliche Nutzungs- und Verwertungsrechte, weltweit, ausschließlich, unwiderruflich
            und ohne jede sachliche und, für die Dauer des Schutzes, zeitliche Beschränkung jeweils mit Wirkung zum Zeitpunkt ihres Entstehens ein. Der Auftraggeber hat insbesondere auch das Recht, die Tätigkeitsergebnisse zu ändern und in der geänderten Form im gleichen Umfang wie in der ursprünglichen Form zu nutzen.
            <br /><br />
            8.4. Ausgenommen von der Rechteübertragung gemäß vorstehend Ziffern 8.2 und 8.3 sind Softwarekomponenten Dritter, die Teil der Softwareentwicklungen sind. An diesen räumt der Auftragnehmer dem Auftraggeber vorbehaltlich einer vollständigen Zahlung der vom Auftraggeber geschuldeten Vergütung sämtliche übertragungsfähigen nicht-ausschließlichen Rechte, insbesondere sämtliche urheberrechtlichen Nutzungs- und Verwertungsrechte, weltweit, unwiderruflich und für die Dauer des Schutzes ohne zeitliche Beschränkung jeweils mit Wirkung zu dem Zeitpunkt ein, zu dem der Auftragnehmer dem Auftraggeber die Tätigkeitsergebnisse übergibt oder zugänglich macht. Im Übrigen unterliegt die Software Dritter, einschließlich Open Source Software, den hierfür geltenden Lizenzverträgen.
            <br /><br />
            8.5. Der Auftraggeber ist zur Weiterübertragung der dem Auftraggeber gem. vorstehend Ziffern 8.2 bis 8.4 übertragenen oder eingeräumten Rechte an Dritte und zur Einräumung von einfachen oder ausschließlichen Nutzungs- und Verwertungsrechten an Dritte befugt, und zwar jeweils vollständig oder teilweise, dauerhaft oder vorübergehend.
            <br /><br />
            8.6. Der Auftragnehmer und deren Lizenzgeber behalten alle Rechte an und in Bezug auf die an den Auftragnehmer lizenzierte oder in ihrem Eigentum befindlichen Softwarekomponenten, Systeme, Daten oder Materialien, Verfahren, Prozesse und Geschäfts- und Betriebsgeheimnissen (Know-How), die vom Auftragnehmer zur Erbringung der Leistungen verwendet werden. Der Auftragnehmer gewährt dem Auftraggeber ein nicht-ausschließliches Recht, das in den Tätigkeitsergebnissen enthaltene Auftragnehmer Background IP zu nutzen, soweit dies für eine vertragsgemäße Nutzung der Tätigkeitsergebnisse erforderlich ist.
            <br /><br />
            8.7. Bis zur vollständigen Zahlung der Vergütung gewährt der Auftragnehmer dem Auftraggeber das nicht-ausschließliche und nicht übertragbare Recht, die von Auftragnehmer dem Auftraggeber zur Verfügung gestellten oder zugänglich gemachten Tätigkeitsergebnisse zu nutzen. Dieses Recht soll es dem Auftraggeber insbesondere ermöglichen, an der zwischen den Parteien vereinbarten Softwareentwicklung vertragsgemäß mitzuwirken.
            <br /><br />
            8.7. Der Auftraggeber nimmt die Rechtseinräumungen gem. Ziffern 8.1 bis 8.7 an.
          </p>
          <h2>9. VERGÜTUNG, PREISE, ZAHLUNGSBEDINGUNGEN</h2>
          <p>
          9.1.  Ein Auftrag gilt als zustande gekommen, sofern eine schriftliche Beauftragung vorliegt. Eine Beauftragung gilt, sofern nicht anders schriftlich vereinbart, für drei Kalendermonate. Sofern nicht anders schriftlich vereinbart, verlängert sich die Beauftragung automatisch zum Monatsende um drei Monate. Die MAKE Europe ist berechtigt, weitere Leistungen auszusetzen, sofern ein Zahlungsverzug von mehr als 28 Tagen nach Rechnungserhalt besteht. Für alle daraus entstehenden Nachteile steht der Auftraggeber vollumfänglich ein.
          <br /><br />
            9.2. Vermittelt der Auftragnehmer eine(n) Mitarbeiter(in) an den Auftraggeber so erhält der Auftragnehmer eine Vermittlungsprämie, die drei Bruttomonatsgehältern (ohne Bonus) des(r) Mitarbeiter(in) entspricht. Die Prämie wird am ersten Arbeitstag des(r) jeweiligen Mitarbeiter(in) fällig.
            <br /><br />
            9.3. Der Auftragnehmer ist berechtigt, von Anreizprogrammen des Auftraggebers, insbesondere bei der Investoren-Suche und in ähnlichen Fällen, zu profitieren.
            <br /><br />
            9.4. Höhe und Art der Vergütung sind im Einzelvertrag bestimmt.
            <br /><br />
            9.5. Ist die Vergütung nach Stundensätzen vereinbart, werden angefangene Stunden anteilig verrechnet. Ist die Vergütung nach Tagessätzen vereinbart, berechnet sich der Tagessatz auf der Basis eines Acht-Stunden-Tags.
            <br /><br />
            9.6. Ist die Vergütung nach Zeitaufwand bemessen, wird der Auftragnehmer dem Auftraggeber monatlich die Vergütung für die im Vormonat geleisteten Tätigkeiten zu den vereinbarten Stunden- oder Tagessätzen berechnen. Die Rechnungen werden eine Übersicht über den berechneten Zeitaufwand für jede Personalkategorie enthalten (Tätigkeitsnachweis). Der Auftraggeber kann den im Tätigkeitsnachweis getroffenen Feststellungen nur binnen zwei Wochen schriftlich widersprechen.
            <br /><br />
            9.7. Vereinbaren die Parteien eine pauschale Vergütung, ist der Auftragnehmer berechtigt, dem Auftraggeber Abschlagszahlungen in Höhe des Wertes der von ihm erbrachten und nach dem Vertrag geschuldeten Leistungen zu berechnen.
            <br /><br />
            9.8. Abgerechnet wird je angefangenen 30 Minuten zum vereinbarten Stunden- oder Tagessatz. Für Beratungsleistungen zwischen 20 Uhr und 8 Uhr erhebt die MAKE Europe einen Aufschlag von 50 Prozent.
            <br /><br />
            9.9. Sämtliche Preise verstehen sich ohne Umsatzsteuer.
          </p>
          <h2>10. ERSTATTUNG VON NEBENKOSTEN</h2>
          <p>
          10.1. Soweit nicht anders vereinbart, trägt der Auftraggeber die im Rahmen der Erbringung der Leistungen anfallenden Materialkosten, Reisekosten und Spesen des Auftragnehmers. Reisekosten und Spesen werden nach tatsächlichem Aufwand und zu pauschalierten Spesensätzen nach Maßgabe der jeweils geltenden gesetzlichen Regelungen berechnet. Reisezeit wird wie Arbeitszeit zum gleichen Stundensatz abgerechnet. Wird im Einzelfall nicht nach Stundensätzen abgerechnet, gilt zur Berechnung der Reisezeit ein angemessener Stundensatz unter Berücksichtigung der Qualifikation.
          <br /><br />
            10.2. Reisekosten werden mit der nächsten Honorarrechnung abgerechnet. Kopien der Reisebelege werden nur auf ausdrücklichen Wunsch des Auftraggebers der Rechnung beigelegt.
            <br /><br />
            10.3. Der Auftragnehmer gewährleistet eine effiziente Reiseorganisation und stimmt sich mit dem Auftraggeber über eventuelle Sonderkonditionen ab, die im Namen des Auftraggebers in Anspruch genommen werden können.
          </p>
          <h2>11. MÄNGELHAFTUNG</h2>
          <p>
          11.1. Bei den durch den Auftragnehmer zur Verfügung gestellten Leistungen handelt es sich weder um Rechts- noch um Steuer- oder Finanzberatung. Sollten Auskünfte von rechtlich oder regulatorisch relevantem Inhalt sein, so handelt es sich lediglich um noch durch den Auftraggeber mittels Experten zu verifizierende Inhalte.
          <br /><br />
          11.2. Der Auftragnehmer erteilt Auskünfte nach bestem Wissen und Gewissen, übernimmt aber als Kommunikationsberatung keinerlei Haftung für deren Richtigkeit.
          <br /><br />
          11.3. Schuldet der Auftragnehmer im Einzelfall eine erfolgsorientierte (werkvertragliche oder werklieferungsvertragliche) Tätigkeit und hat der Auftragnehmer die Leistung nicht frei von Sach- oder Rechtsmängeln verschafft, ist der Auftragnehmer nach eigener Wahl zur Mangelbeseitigung oder Ersatzlieferung (insgesamt „Nacherfüllung“) berechtigt. Schlägt die
          Nacherfüllung fehl, kann der Auftraggeber nach seiner Wahl vom Vertrag zurücktreten oder die Vergütung herabsetzen. Von einem Fehlschlagen der Nacherfüllung ist erst auszugehen, wenn dem Auftragnehmer hinreichende Gelegenheit zur Nacherfüllung eingeräumt wurde, ohne dass der gewünschte Erfolg erzielt wurde. Die gesetzlichen Fälle der Entbehrlichkeit der Fristsetzung bleiben unberührt.
          <br /><br />
          11.4. Es bestehen keine Mängelansprüche für unwesentliche Abweichungen von der vereinbarten Beschaffenheit, die den Gebrauch der Leistung nicht besonders hindern.
          <br /><br />
          11.5. Schadensersatzansprüche wegen Mängeln stehen dem Auftraggeber nur zu, soweit die Haftung des Auftragnehmers nicht nach Maßgabe von Ziffer 12 dieser Allgemeinen Geschäftsbedingungen ausgeschlossen oder beschränkt ist.
          <br /><br />
          11.6. Mängelansprüche verjähren in 12 Monaten ab Abnahme. Hiervon ausgenommen sind Schadensersatzansprüche sowie Mängelansprüche bei arglistigem Verschweigen eines Mangels.
          <br /><br />
          </p>
          <h2>12. HAFTUNG</h2>
          <p>
          12.1. Der Auftragnehmer haftet unbeschränkt für vorsätzlich oder grob fahrlässig verursachte Schäden.
          <br /><br />
          12.2. Bei leicht fahrlässiger Verletzung einer Hauptleistungspflicht oder einer Nebenpflicht, deren Verletzung die Erreichung des Vertragszwecks gefährdet oder deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Kunde vertrauen durfte (nachfolgend „wesentliche Nebenpflicht“), ist die Haftung des Auftragnehmers auf bei Vertragsschluss vorhersehbare, vertragstypische Schäden begrenzt. Bei leicht fahrlässiger Verletzung von Nebenpflichten, die
          nicht zu den wesentlichen Nebenpflichten gehören, haftet der Auftragnehmer nicht.
          <br /><br />
          12.3. Die vorstehenden Haftungsausschlüsse dieser Ziffer 12 berühren nicht die Haftung des Auftragnehmers für eine übernommene Beschaffenheitsgarantie, für Arglist, für Schäden aus der Verletzung von Leben, Körper und Gesundheit sowie für Produktfehler nach Maßgabe des Produkthaftungsgesetzes. Eine Änderung der Beweislast zum Nachteil des Auftraggebers ist hiermit nicht verbunden.
          <br /><br />
          12.4. Soweit die Haftung nach dieser Ziffer 12 ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung der Angestellten, Mitarbeiter, Vertreter und Erfüllungsgehilfen des Auftragnehmers.
          </p>
          <h2>13. LIEFERUNG UND ABNAHME</h2>
          <p>
          13.1. Die von dem Auftragnehmer erbrachten Tätigkeitsergebnisse werden von dem Auftragnehmer gemäß der vertraglichen Leistungsbeschreibung geliefert und im Falle von abnahmefähigen Arbeitsergebnissen durch den Auftraggeber geprüft und abgenommen. Der Auftraggeber informiert den Auftragnehmer unverzüglich schriftlich über etwaige Mängel, die in der Abnahmeprüfung entdeckt werden, einschließlich einer angemessen detaillierten Spezifikation der Art und Bedingungen dieser Mängel („Mängelbericht“). Die Arbeitsergebnisse gelten als abgenommen, wenn kein Mängelbericht innerhalb von 10 Werktagen nach Lieferung bei dem Auftragnehmer eingeht.
          <br /><br />
          13.2. Die Angabe eines Mangels hindert eine Abnahme nur, wenn der angegebene Mangel tatsächlich vorliegt.
          <br /><br />
          13.3. Wegen unwesentlicher Mängel kann die Abnahme nicht verweigert werden
          </p>
          <h2>14. DATENSCHUTZ, GEHEIMHALTUNG UND SICHERHEIT</h2>
          <p>
          14.1. Der Auftraggeber gibt dem Auftragnehmer alle relevanten Sachverhalte bekannt, deren Kenntnis für ihn aus Gründen des Datenschutzes und der Geheimhaltung erforderlich ist.
          <br /><br />
          14.2. Vor Übergabe oder Zurverfügungstellung von Daten an den Auftragnehmer stellt der Auftraggeber die Löschung schutzwürdiger Inhalte sicher, soweit deren Kenntnis nicht zur Erbringung der Leistungen erforderlich ist.
          <br /><br />
          14.3.  Der Auftragnehmer sorgt dafür, dass alle Personen, die von ihm mit der Bearbeitung oder Erfüllung des Vertrags betraut sind, die gesetzlichen Bestimmungen über den Datenschutz beachten. Die nach Datenschutzrecht erforderliche Verpflichtung auf das Datengeheimnis ist spätestens vor der erstmaligen Aufnahme der Tätigkeit vorzunehmen und dem Auftraggeber auf Verlangen schriftlich zu bestätigen.
          <br /><br />
          14.4. Macht es die Vorbereitung und Durchführung der Zusammenarbeit erforderlich, dass der Auftragnehmer personenbezogene Daten im Auftrag des Auftraggebers verarbeitet, wird er diese Daten im Einklang mit den Bestimmungen der europäischen Datenschutzgrundverordnung (DSGVO) verarbeiten. Dazu schließen die Parteien eine Vereinbarung zur Auftragsverarbeitung ab.
          </p>
          <h2>15. UNABHÄNGIGE VERTRAGSPARTNER</h2>
          <p>
          Die Parteien sind voneinander unabhängig. Zwischen den Parteien bestehen keine Gesellschaft, kein Joint Venture und keine vergleichbare rechtliche Beziehung. Keine Partei ist berechtigt, die jeweils andere Partei rechtsgeschäftlich zu vertreten. Jede Partei ist ausschließlich gegenüber eigenem Personal zur Anleitung, Beaufsichtigung und Weisungserteilung befugt. Die Arbeitnehmer der einen Partei sind zu keinem Zeitpunkt in die Betriebs- und Arbeitsorganisation der anderen Partei eingegliedert.
          </p>
          <h2>16. TEXTFORM</h2>
          <p>
          Soweit nichts anderes geregelt ist, bedürfen vertragliche Mitteilungen und Erklärungen mindestens der Textform.
          </p>
          <h2>17. SCHLUSSBESTIMMUNGEN</h2>
          <p>
          17.1. Es gilt deutsches Recht, mit Ausnahme von dessen Regelungen über die Rechtswahl, die zur Anwendung einer anderen Rechtsordnung führen würden. Die Geltung des CISG (”UN-Kaufrecht”) wird ausgeschlossen. Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist Fulda. Der Auftragnehmer ist jedoch berechtigt, an jedem anderen gesetzlichen
Gerichtsstand zu klagen.
          <br /><br />
          17.2. Sollten Bestimmungen dieser Allgemeinen Geschäftsbedingungen oder des Einzelvertrages ungültig sein oder werden, wird die Wirksamkeit der anderen Vertragsbestimmungen hierdurch nicht berührt. Die Parteien verpflichten sich bereits jetzt, die etwa unwirksame Bestimmung durch eine solche wirksame zu ersetzen, die der unwirksamen Bestimmung in ihrer wirtschaftlichen Zielsetzung am nächsten kommt.
          <br /><br />
          17.3. In diesem Vertrag sind alle den Vertragsgegenstand betreffenden Vereinbarungen zwischen den Vertragsparteien vollständig wiedergegeben. Nebenabreden bestehen nicht. Änderungen und Ergänzungen bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Aufhebung des Schriftformerfordernisses.
          </p>
          <p className="conclusion">
            Fulda, Deutschland (EU), 05.09.2021
          </p>
          <p>
            <strong>Erfüllungsort:</strong><br />
            Make Europe GmbH, Im Alten Roth 7<br />
            36039 Dietershan (Fulda, Germany HRB 6756)<br />
            Geschäftsführer: Roman Keßler<br />
            Steuernummer: 018 239 02547<br />
            VAT-ID: DE 301898495<br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AGB;
