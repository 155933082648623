import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Product.css'; // Adjust the path if necessary
import productImage1 from '../assets/Lisai.png';
import productImage2 from '../assets/nilskeesmekers.png';
import productImage3 from '../assets/jameswiseman.png';
import productImage4 from '../assets/shubhamdhage.png';

const Product = () => {
  const navigate = useNavigate();

  const products = [
    {
      name: 'Generative AI: Social Content Booster',
      img: productImage1,
      link: '/products/product1',
    },
    {
      name: 'Productivity Booster: Business Process Automation',
      img: productImage2,
      link: '/products/product2',
    },
    {
      name: 'Web & Mobile App Development',
      img: productImage3,
      link: '/products/product3',
    },
    {
      name: 'Web 3 & dApps Development',
      img: productImage4,
      link: '/products/product4',
    },
  ];

  return (
    <div className="product-page">
      <h1>Our Products and Services</h1>
      <div className="product-grid">
        {products.map((product) => (
          <div className="product-tile" key={product.name}>
            <img src={product.img} alt={product.name} className="product-image" />
            <h3>{product.name}</h3>
            <button className="cta-button" onClick={() => navigate(product.link)}>
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
