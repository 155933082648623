import React from 'react';
import '../styles/productstyles/product1.css'; // Use the common CSS file for consistency
import ProductTabs from '../components/ProductTabs'; // Import the ProductTabs component
import FloatingMenu from '../components/FloatingMenu'; // Import the FloatingMenu component
import productImage from '../assets/Lisai.png'; // Use the relevant image for this product
import nobotImage from '../assets/nobot.png'; // Placeholder image for specialties
import strategyImage from '../assets/strategy.png'; // Placeholder image for specialties
import engagementImage from '../assets/engagement.png'; // Placeholder image for specialties

const ProductLanding1 = () => {
  return (
    <div className="brand-landing">
      {/* Render ProductTabs for desktop users */}
      <ProductTabs />

      {/* Render FloatingMenu for mobile users */}
      <FloatingMenu />

      {/* Hero Section */}
      <div className="box box--dark">
        <img src={productImage} alt="Social Content Booster" className="brand-hero-image-300" />
        <h1><u><i>Top Seller!</i></u>  Our "Quality Content Booster"</h1>
        <p><center>Boost Your Social Media Game with our Generative AI: Lisa</center></p>
      </div>

      {/* Services Section */}
      <div className="box box--light">
      <h2>Who is Lisa Luckas?</h2>
      <p>
          Lisa Luckas (AI) is an AI-driven tool designed to manage and optimize social media presence. Few generative AI solutions are as well-tested as Lisa Luckas AI.
          </p>
          <h2>Tested and Celebrated by Top-Brands</h2>
          <p>
  Since early 2024, Lisa has been trained and tested. Her produced newsy content garnered over 1 million views on LinkedIn.
  It’s an excellent way to boost organic posts and engagement without spending on ads.
  <a href="https://nobot.news/about-nobot-news/"> Check out the full story at nobot.news</a>.
</p>

        <h2>Available Services</h2>
        <ul>
          <li><strong>AI-Generated Social Media Posts:</strong> Automate your content creation with AI.</li>
          <li><strong>Content Strategy Consultation:</strong> Tailored strategies to enhance your online presence.</li>
          <li><strong>Performance Analytics:</strong> Track and optimize your social media engagement.</li>
        </ul>
      </div>

      {/* Company Specialties Section */}
      <div className="box box--dark">
        <h2>Our Specialties in Social Content Management</h2>
        <p><center>We offer innovative solutions to maximize your social media presence:</center></p>
        <div className="specialties-logos">
          <div className="specialty">
            <img src={strategyImage} alt="AI Content Creation" />
            <p><center>AI Content Creation</center></p>
          </div>
          <div className="specialty">
            <img src={nobotImage} alt="Content Strategy" />
            <p><center>Content + SEO Strategy</center></p>
          </div>
          <div className="specialty">
            <img src={engagementImage} alt="Community Engagement" />
            <p><center>Community Engagement</center></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLanding1;
