import React from 'react';
import '../styles/brandstories/BrandStory5.css'; // Updated CSS file for BrandStory5
import bufficornImage from '../assets/Bufficorn.png'; // Bufficorn image
import ethDenverLogo from '../assets/ethdenverlogo.png'; // ETHDenver logo
import celoLogo from '../assets/celologo.png'; // Celo logo
import polkadotLogo from '../assets/polkadot.png'; // Polkadot logo
import coloradoLogo from '../assets/sealcolorado.png'; // Colorado State logo

const BrandStory5 = () => {
  return (
    <div className="brand-landing">
      {/* Hero Section */}
      <div className="box box--dark">
        <img src={bufficornImage} alt="Make Europe GmbH" className="brand-hero-image-300" />
        <h1>Recognized for Excellence</h1>
        <p><center>
          Make Europe won many awards at international development competitions. 
          </center></p>
      </div>

      {/* Mission Section */}
      <div className="box box--light">
      <center>
      <div className="ethdenver-logo">
            <img src={ethDenverLogo} alt="ETHDenver Mobile Track" />
          </div>
          </center>
      <p>
      <b>ETHDenver Mobile Track Winners!  </b>In 2022, Make Europe GmbH was honored in Denver and competing against over 2,000 global teams. <br></br><br></br>We are "Bufficorns from the old country"
        </p>
      </div>

      {/* Achievements Section */}
      <div className="box box--dark">
        <p>
          Our participation in ETHDenver 2022 showcased our commitment to innovation and excellence in Web3 technology.
          We won in several categories, demonstrating the strength and impact of our solutions:
        </p>
        <div className="ethdenver-logos">
          <div className="ethdenver-logo">
            <img src={celoLogo} alt="Layer1 | Celo" />
            <p>Celo Foundation</p>
          </div>
          <div className="ethdenver-logo">
            <img src={polkadotLogo} alt="Parachains | Web3 Foundation" />
            <p>Web3 Foundation</p>
          </div>
          <div className="ethdenver-logo">
            <img src={coloradoLogo} alt="ColoradoJam | State of Colorado" />
            <p>State of Colorado</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandStory5;
