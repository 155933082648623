import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ProductSection from './components/ProductSection';
import Product from './components/Product'; // Import the Product component
import Brand from './components/Brand';
// import AppointmentSection from './components/AppointmentSection';
import Footer from './components/Footer';
import Product1 from './products/product1';
import Product2 from './products/product2';
import Product3 from './products/product3';
import Product4 from './products/product4';
import ScrollToTop from './components/ScrollToTop';
import BrandStory1 from './brand/brandstory1';
import BrandStory2 from './brand/brandstory2';
import BrandStory3 from './brand/brandstory3';
import BrandStory4 from './brand/brandstory4';
import BrandStory5 from './brand/brandstory5';
import AGB from './components/AGB'; // Import the AGB component

function App() {
  return (
    <Router>
      <ScrollToTop />
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const location = useLocation();

  // Check if the current path is a BrandStory page
  const isBrandStoryPage = location.pathname.startsWith('/brand/brandstory');

  return (
    <div className="App">
      <Routes>
        {/* Render ProductSection at the root path */}
        <Route path="/" element={<ProductSection />} />
        <Route path="/product" element={<Product />} /> {/* Added route for /product */}
        <Route path="/products/product1" element={<Product1 />} />
        <Route path="/products/product2" element={<Product2 />} />
        <Route path="/products/product3" element={<Product3 />} />
        <Route path="/products/product4" element={<Product4 />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/brand/brandstory1" element={<BrandStory1 />} />
        <Route path="/brand/brandstory2" element={<BrandStory2 />} />
        <Route path="/brand/brandstory3" element={<BrandStory3 />} />
        <Route path="/brand/brandstory4" element={<BrandStory4 />} />
        <Route path="/brand/brandstory5" element={<BrandStory5 />} />
        {/* Add the route for AGB */}
        <Route path="/agb" element={<AGB />} />
      </Routes>

      {/* Conditionally render the Product page and Brand carousel on BrandStory pages */}
      {isBrandStoryPage && (
        <>
          <Product /> {/* Product component */}
          <Brand />   {/* Existing Brand carousel */}
        </>
      )}

      {/* Always display AppointmentSection and Footer  
      <AppointmentSection />*/}
      <Footer />
    </div>
  );
}

export default App;
